
  import React from 'react'
  import { createBundle, composeBundles } from 'core/bundler'
  import { Provider, useStore, Router, Route, Redirect, Switch, Link, useLocation, useHistory, useRouteMatch } from 'core/react-bindings'
  import appTime from 'core/modules/app-time'
  import asyncCount from 'core/modules/async-count'
  import debug from 'core/modules/debug'
  import localCache from 'core/modules/local-cache'
  import reactors from 'core/modules/reactors'
  import router, { history } from 'core/modules/router'

  import apiBundle from '../../src/bundles/api/bundle'

  import registrationBundle from '../../src/app/insight/bundles/registration/bundle'

  import systemBundle from '../../src/app/insight/bundles/system/bundle'

  import permissionsBundle from '../../src/app/insight/bundles/permissions/bundle'

  import licencesBundle from '../../src/app/insight/bundles/licences/bundle'

  import contractsBundle from '../../src/app/insight/bundles/contracts/bundle'

  import paymentsBundle from '../../src/app/insight/bundles/payments/bundle'

  import sseBundle from '../../src/app/insight/bundles/sse/bundle'

  import insightBundle from '../../src/app/insight/bundles/insight/bundle'

  import userPrefsBundle from '../../src/bundles/userPrefs/bundle'

  import pipelineBundle from '../../src/app/insight/bundles/pipeline/bundle'

  import pipelineManagerBundle from '../../src/app/insight/bundles/pipeline-manager/bundle'

  import trainingWizardBundle from '../../src/app/insight/bundles/training-wizard/bundle'

  import scoringWizardBundle from '../../src/app/insight/bundles/scoring-wizard/bundle'

  import uploadCsvBundle from '../../src/app/insight/bundles/upload-csv/bundle'

  import transformationBundle from '../../src/app/insight/bundles/transformation/bundle'

  import dynamicDatasetBundle from '../../src/app/insight/bundles/dynamic-dataset/bundle'

  import resourceExplanationsBundle from '../../src/app/insight/bundles/resource-explanations/bundle'

  import logsBundle from '../../src/app/insight/bundles/logs/bundle'

  import automationsBundle from '../../src/app/insight/bundles/automations/bundle'

  import chargebeeBundle from '../../src/app/insight/bundles/chargebee/bundle'

  import authBundle from '../../src/app/insight/bundles/auth/bundle'

  import resourceDatasetsBundle from '../../src/app/insight/bundles/resource-datasets/bundle'

  import resourceTransformationsBundle from '../../src/app/insight/bundles/resource-transformations/bundle'

  import resourceTrainingsBundle from '../../src/app/insight/bundles/resource-trainings/bundle'

  import resourceBrainsBundle from '../../src/app/insight/bundles/resource-brains/bundle'

  import resourceScoringsBundle from '../../src/app/insight/bundles/resource-scorings/bundle'

  import resourceDataBundle from '../../src/app/insight/bundles/resource-data/bundle'
  


const store = composeBundles(
  appTime,
  asyncCount,
  debug,
  localCache,
  reactors,
  router,
  apiBundle({"backend":{"path":"https://api.guufii.de","options":{"headers":{"Content-Type":"application/json"}},"authorization":{"type":"Bearer","source":"store","selector":"selectToken"}},"upload":{"path":"https://upload.guufii.de","authorization":{"type":"Bearer","source":"store","selector":"selectToken"}},"message":{"path":"https://message.guufii.de","authorization":{"type":"Bearer","source":"store","selector":"selectToken"}},"view":{"path":"https://view.guufii.de","options":{"headers":{"Content-Type":"application/json"}},"authorization":{"type":"Bearer","source":"store","selector":"selectToken"}},"transform":{"path":"https://transform.guufii.de","options":{"headers":{"Content-Type":"application/json"}},"authorization":{"type":"Bearer","source":"store","selector":"selectToken"}}}),
 registrationBundle({}),
 systemBundle({}),
 permissionsBundle({}),
 licencesBundle({}),
 contractsBundle({}),
 paymentsBundle({}),
 sseBundle({}),
 insightBundle({}),
 userPrefsBundle({}),
 pipelineBundle({}),
 pipelineManagerBundle({}),
 trainingWizardBundle({}),
 scoringWizardBundle({}),
 uploadCsvBundle({}),
 transformationBundle({}),
 dynamicDatasetBundle({}),
 resourceExplanationsBundle({}),
 logsBundle({}),
 automationsBundle({}),
 chargebeeBundle({}),
 authBundle(undefined),
 resourceDatasetsBundle(undefined),
 resourceTransformationsBundle(undefined),
 resourceTrainingsBundle(undefined),
 resourceBrainsBundle(undefined),
 resourceScoringsBundle(undefined),
 resourceDataBundle(undefined)
)

  const Core = ({ preloadedData, children }) => (
    <Provider store={store(preloadedData)}>
      <Router history={history}>{children}</Router>
    </Provider>
  )
  export default Core
  export { useStore, Route, Redirect, Switch, Link, useLocation, useHistory, useRouteMatch }
